import {
    Create,
    CheckboxGroupInput,
    useNotify,
    useDataProvider,
    Loading,
    useTranslate,
    TabbedForm,
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    required,
    NumberInput
} from 'react-admin';
import { useQuery } from '@tanstack/react-query';
import {Alert} from "@mui/material";
import {AppPermissions} from "../../data/constants/permissions";
import React from "react";

function cleanData(data: any) {
    return {
        ...data,
        permissions: data.permissions.map((item: any) => {
            return {
                ...item,
                permissions: item.permissions ?? []
            }
        })
    }
}

const SettingsPage = () => {
    const notify = useNotify();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const { data, isPending, error } = useQuery({
        queryKey: ['config', 'get'],
        queryFn: () => dataProvider.getConfig()
    });

    const onSuccess = () => {
        notify(`resources.configuration.messages.saved`, {type: 'info'});
    };

    if (isPending) return <Loading />;
    if (error) return <Alert variant={'outlined'} title={error.message} severity={'error'}/>;

    return (
        <>
            <h1>{translate('resources.configuration.pageTitle')}</h1>
            <Create resource="configuration" mutationOptions={{ onSuccess }} record={data} transform={cleanData}>
                <TabbedForm  syncWithLocation={false}>
                    <TabbedForm.Tab label="resources.configuration.tabs.cdrs" path="cdrs">
                        <Alert severity={'info'} sx={{marginBottom: 2}}>{translate('resources.configuration.cdrs.no_tariff_fallbacks.description')}</Alert>
                        <ArrayInput source={'cdrs.no_tariff_fallbacks'} label={'resources.configuration.cdrs.no_tariff_fallbacks.label'}>
                            <SimpleFormIterator>
                                <NumberInput source="amperage" validate={required()} placeholder="16"/>
                                <NumberInput source="voltage" validate={required()} placeholder="220"/>
                                <TextInput source="power_type" validate={required()} placeholder="AC_1_PHASE"/>
                                <NumberInput source="price" validate={required()} />
                                <TextInput source="description" validate={required()}/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="resources.configuration.tabs.permissions" path="permissions">
                        <ArrayInput source={'permissions'}>
                            <SimpleFormIterator>
                                <TextInput source="group" validate={required()}/>
                                <CheckboxGroupInput choices={Object.keys(AppPermissions).map(key => {
                                    // @ts-ignore
                                    return {id: AppPermissions[key], name: "resources.configuration.permissions.permissions." + key}
                                })} source="permissions" row={false} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </TabbedForm.Tab>
                </TabbedForm>
            </Create>
        </>
    );
};

SettingsPage.route = '/configuration';

export default SettingsPage;
