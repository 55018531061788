import {
    ArrayField,
    BooleanField, ChipField,
    DatagridConfigurable,
    DateField, ExportButton,
    FilterButton,
    List,
    SelectColumnsButton, SingleFieldList,
    TextField,
    TopToolbar,
    Button, useDataProvider, FunctionField,
} from 'react-admin';
import {Box, Container, Typography} from "@mui/material";
import ListIcon from "@mui/icons-material/ElectricalServices";

import SearchHintInput from "../../../components/input/SearchHintInput";

import {useState} from "react";
import {ButtonOpendataParameters} from "./widgets/ButtonOpendataParameters";
import exporter from "./exporter";
import {AppPermissions} from "../../../data/constants/permissions";
import {useIsAllowed} from "../../../../helpers/useIsAllowed";
import SelectTagsInput from "./widgets/SelectTagsInput";
import Chip from "@mui/material/Chip";

const EmptyList = () => {
  return (
      <Container sx={{display: 'flex', justifyContent: 'center', opacity: 0.5}} className={'RaEmpty-message'}>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <ListIcon className={'RaEmpty-icon'} sx={{width: '9em', height: '9em'}}/>
          <Typography variant="h4" paragraph>
            Pas de locations
          </Typography>
        </Box>
      </Container>
  );
};

const listFilters = [
    <SearchHintInput source="q" alwaysOn key={'search'} hint={"Rechercher une chaine de caracteres (sensible à la casse) parmi : id, nom, ville, code postal."} />,
    <SelectTagsInput source="tags" onCreate={undefined} key={'tags'} label={'tags'} />,
];

const OpenDataExportButton = () => {
  const provider = useDataProvider();
  const [loading, setLoading] = useState(false);

  const handleDl = () => {
    setLoading(true);
    provider.getOpendataFile()
        .then((response: any) => response.blob())
        .then((response: any) => {
          const link = document.createElement("a");
          link.target = "_blank";
          link.download = `opendata.csv`;
          link.href = URL.createObjectURL(
              new Blob([response], { type: "text/csv" })
          );
          link.click();

          // Clean up and remove the link
          link.remove();
        })
        .finally(() => setLoading(false))
  }

  return <Button onClick={handleDl} label={'Export opendata'} variant={'outlined'} disabled={loading}/>;
}

const ListActions = () => {
    const { isAllowed } = useIsAllowed();

    return (
        <TopToolbar>
            <SelectColumnsButton/>
            <FilterButton/>
            <ExportButton/>
            {isAllowed(AppPermissions.ExploitationLocationsOpenDataView) && <OpenDataExportButton/>}
            {isAllowed(AppPermissions.ExploitationLocationsOpenDataEdit) && <ButtonOpendataParameters/>}
        </TopToolbar>
  );
};

export const LocationList = () => {
  return (
    <List actions={<ListActions/>} filters={listFilters} exporter={exporter} empty={<EmptyList/>}>
      <DatagridConfigurable rowClick="edit" bulkActionButtons={false}
                            omit={[
                              'address',
                              'postal_code',
                              'address',
                              'country',
                              'latitude',
                              'longitude',
                              'connectors',
                              'evses',
                            ]}>
        <TextField source="name" sortable={false}/>
        <TextField source="address" sortable={false}/>
        <TextField source="postal_code" sortable={false}/>
        <TextField source="city" sortable={false}/>
        <TextField source="country" sortable={false}/>
        <TextField source="coordinates.latitude" sortable={false} label={'latitude'}/>
        <TextField source="coordinates.longitude" sortable={false} label={'longitude'}/>
        <TextField source="type" sortable={false}/>
          <FunctionField label="tags" source="metadata.tags" sortable={false} render={(record) => <Box sx={{display: 'flex', gap: 1}}>
              {record.metaData?.tags?.map((tag: string) => <Chip key={tag} label={tag}/>)}
          </Box>}>
          </FunctionField>
        <ArrayField label="EVSES" source="evses" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="evse_id" />
          </SingleFieldList>
        </ArrayField>
        <ArrayField label="Connecteurs" source="evses" sortable={false}>
            <SingleFieldList linkType={false}>
                <ArrayField label="Connectors" source="connectors">
                    <SingleFieldList linkType={false}>
                        <ChipField source="id" />
                    </SingleFieldList>
                </ArrayField>
            </SingleFieldList>
        </ArrayField>
        <BooleanField source="openData.enable_sync" looseValue={true} sortable={false} label={'location.column.opendata.enable_sync'}/>
        <DateField source="last_updated" showTime={true} sortable={false}/>
        <DateField source="metaData.activationDate" showTime={true} sortable={false}/>
        <TextField source="id" sortable={false}/>
      </DatagridConfigurable>
    </List>
  );
};
