import {DataProvider, fetchUtils} from 'react-admin';
import { stringify } from 'query-string';
import appConfig from "../config";
import {MonthIssuersStats} from "./dto/MonthIssuersStats";
import { LocationStats } from './dto/LocationStats';
import { CdrStatistics, CdrStatisticsOverTime } from './dto/CdrStats';
import { DashboardDateOption } from '../admin/data/constants/dashboard-date-option';
import { CdrDashboardType } from '../admin/data/constants/cdr-dashboard-type';
import { DeployStats } from './dto/DeployStats';
import { DeployPlanning } from './dto/DeployPlanning'
import {Configuration} from "./dto/Configuration";

const apiUrl = appConfig.BASE_API_URL;

const httpClient = (url: string, options : any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    const token = localStorage.getItem('token')

    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
    }

    return fetchUtils.fetchJson(url, options).catch((err) => {
        if (err.body && err.body.errorMessage) {
            err.message = err.body.errorMessage;
        }
        return Promise.reject(err)
    });
};

const httpClientPlain = (url: string, options : any = {}) => {
    if (!options.headers) {
        options.headers = new Headers();
    }

    const token = localStorage.getItem('token')

    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
    }

    return fetch(url, options).catch((err) => {
        if (err.body && err.body.errorMessage) {
            err.message = err.body.errorMessage;
        }
        return Promise.reject(err)
    });
};

const mapResourceToEndpoint = (resource: string) : string => {
    switch (resource) {
        case 'exploitation-locations':
            return 'exploitation/locations';

        case 'exploitationEmsps':
            return 'exploitation/emsps';

        case 'invoicingIssuers':
            return 'invoicing/issuers';
    }

    return resource;
}

const mapItemFromApi = (resource: string, item: any) => {
    switch (resource) {
        case 'tariffgroups':
            return {...item, id: item.tariffgroup_id};

        case 'tarifflinks':
            return {...item, id: item.location_id + item.party_id + item.tariffgroup_id};

        case 'emsps':
        case 'exploitationEmsps':
            return {...item, id: item.party_id};

        case 'configuration':
            return {...item, id: 'config'};
    }

    return item;
}

export type E55cApiDataProvider = DataProvider & {
    generateInvoices() : Promise<void>,
    downloadInvoice({issuerId, invoiceId}: {issuerId: string, invoiceId: string}): Promise<Response>,
    sendInvoice({issuerId, invoiceId}: {issuerId: string, invoiceId: string}): Promise<Response>,
    getInvoicingConfig(): Promise<any>,
    saveInvoicingConfig(data: any): Promise<any>,
    getPreviousMonthIssuersStats(): Promise<MonthIssuersStats>,
    getLocationStats(): Promise<LocationStats>,
    getPartyCdrStats(dateOpt: DashboardDateOption): Promise<CdrStatistics[]>,
    getIssuerCdrStats(dateOpt: DashboardDateOption, party: string): Promise<CdrStatistics[]>,
    getCdrOverTimeStats(typeOpt: CdrDashboardType, party: string): Promise<CdrStatisticsOverTime[]>,
    getDeployStats(): Promise<DeployStats>,
    getDeployPlanning(): Promise<DeployPlanning>,
    getPartyList(): Promise<string[]>,
    getLocationsTags(): Promise<string[]>,
}

const Provider : E55cApiDataProvider = {
    getList: (resource: string, params: any) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${mapResourceToEndpoint(resource)}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            return ({
                data: json.items.map((item: any) => mapItemFromApi(resource, item)),
                total: parseInt(json.total),
            });
        });
    },

    getOne: (resource: string, params: any) => {
        return httpClient(`${apiUrl}/${mapResourceToEndpoint(resource)}/${encodeURIComponent(params.id)}`)
            .then(({json}) => ({data: mapItemFromApi(resource, json)}));
    },

    getMany: (resource: string, params: any) => {
        const query = {
            filter: JSON.stringify({ ids: params.ids }),
        };

        let url = `${apiUrl}/${mapResourceToEndpoint(resource)}?${stringify(query)}`;

        if (resource === 'locations') {
            url = `${apiUrl}/locations/many?${stringify(query)}`;
        }

        return httpClient(url).then(({ json }) => {
            return ({data: json.items.map((item: any) => mapItemFromApi(resource, item))});
        });
    },

    getManyReference: (resource: string, params: any) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${mapResourceToEndpoint(resource)}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.items.map((item: any) => mapItemFromApi(resource, item)),
            total: parseInt(json.total, 10),
        }));
    },

    create: (resource: string, params: any) => {
        const data = resource === 'tariffgroups' ? filterDataUpdateTariffgroup(params.data) : params.data;

        return httpClient(`${apiUrl}/${mapResourceToEndpoint(resource)}`, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(({json}) => ({data: mapItemFromApi(resource, json)}));
    },

    update: (resource: string, params: any) => {
        const data = resource === 'tariffgroups' ? filterDataUpdateTariffgroup(params.data) : params.data;

        return httpClient(`${apiUrl}/${mapResourceToEndpoint(resource)}/${encodeURIComponent(params.id)}`, {
            method: 'PATCH',
            body: JSON.stringify(data),
        }).then(({json}) => ({data: mapItemFromApi(resource, json)}));
    },

    updateMany: (resource: string, params: any) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${mapResourceToEndpoint(resource)}?${stringify(query)}`, {
            method: 'PATCH',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    delete: (resource: string, params: any) => {
        return httpClient(`${apiUrl}/${mapResourceToEndpoint(resource)}/${encodeURIComponent(params.id)}`, {
            method: 'DELETE',
        }).then(({json}) => ({data: json}));
    },

    deleteMany: (resource: string, params: any) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${mapResourceToEndpoint(resource)}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    deleteTarifflinks: ({location_id, party_id, tariffgroup_id} : {location_id: string, party_id: string, tariffgroup_id: string}) =>
        httpClient(`${apiUrl}/tarifflinks?location_id=${encodeURIComponent(location_id)}&party_id=${encodeURIComponent(party_id)}&tariffgroup_id=${encodeURIComponent(tariffgroup_id)}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    syncCdrs: ({dateFrom, dateTo}: {dateFrom: string, dateTo: string}) => {
        return httpClient(`${apiUrl}/cdrs-sync`, {
            method: 'POST',
            body: JSON.stringify({dateFrom, dateTo}),
        }).then(({ json }) => ({ data: json }));
    },

    addSyncjob: ({dateFrom, dateTo, emsps}: {dateFrom: string, dateTo: string, emsps?: string[]}) => {
        return httpClient(`${apiUrl}/cdr-import-jobs`, {
            method: 'POST',
            body: JSON.stringify({dateFrom, dateTo, emsps}),
        }).then(({ json }) => ({ data: json }));
    },

    getOpendataFile: () => {
        return httpClientPlain(`${apiUrl}/opendata/get-file`, {
            method: 'GET',
        });
    },

    getOpendataConfig: () => {
        return httpClient(`${apiUrl}/opendata/config`, {
            method: 'GET',
        }).then(({ json }) => json);
    },

    saveOpendataConfig: (data: any) => {
        return httpClient(`${apiUrl}/opendata/config`, {
            method: 'PATCH',
            body: JSON.stringify(data),
        });
    },

    generateInvoices() : Promise<void> {
        return httpClient(`${apiUrl}/invoicing/generate-invoices`, {
            method: 'POST',
        }).then(({ json }) => json);
    },

    downloadInvoice({issuerId, invoiceId}: {issuerId: string, invoiceId: string}) : Promise<any> {
        return httpClientPlain(`${apiUrl}/invoicing/issuers/${issuerId}/invoices/${invoiceId}/download`, {
            method: 'GET',
        });
    },

    sendInvoice({issuerId, invoiceId}: {issuerId: string, invoiceId: string}): Promise<any> {
        return httpClient(`${apiUrl}/invoicing/issuers/${issuerId}/invoices/${invoiceId}/send`, {
            method: 'POST',
        });
    },

    getInvoicingConfig: () => {
        return httpClient(`${apiUrl}/invoicing/config`, {
            method: 'GET',
        }).then(({ json }) => json);
    },

    saveInvoicingConfig: (data: any) => {
        return httpClient(`${apiUrl}/invoicing/config`, {
            method: 'PATCH',
            body: JSON.stringify(data),
        });
    },

    getDailyStatsMailingConfig: () => {
        return httpClient(`${apiUrl}/exploitation/mailing-stats/config`, {
            method: 'GET',
        }).then(({ json }) => json);
    },

    saveDailyStatsMailingConfig: (data: any) => {
        return httpClient(`${apiUrl}/exploitation/mailing-stats/config`, {
            method: 'POST',
            body: JSON.stringify(data),
        });
    },

    sendTestStatsEmail: (data: any) => {
        return httpClient(`${apiUrl}/exploitation/mailing-stats/send-test`, {
            method: 'POST',
            body: JSON.stringify(data),
        });
    },

    resendStatsEmail: (data: any) => {
        return httpClient(`${apiUrl}/exploitation/mailing-stats/resend`, {
            method: 'POST',
            body: JSON.stringify(data),
        });
    },

    getPreviousMonthIssuersStats: async (): Promise<MonthIssuersStats> => {
        const {json} = await httpClient(`${apiUrl}/invoicing/stats/issuers/all/previous-month`, {
            method: 'GET',
        });
        return json;
    },

    getLocationStats: async (): Promise<LocationStats> => {
        const {json} = await httpClient(`${apiUrl}/exploitation/locations/location-stats`, {
            method: 'GET',
        });
        return json;
    },

    getDeployStats : () : Promise<DeployStats> => {
        return httpClient(`${apiUrl}/exploitation/locations/stats/deploy`, {
            method: 'GET',
        }).then(({json}) => {
            return json;
        });
    },

    getDeployPlanning : () : Promise<DeployPlanning> => {
        return httpClient(`${apiUrl}/exploitation/locations/stats/deploy-planning`, {
            method: 'GET',
        }).then(({json}) => {
            return json;
        });
    },

    getApiVersion : () : Promise<string> => {
        return httpClient(`${apiUrl}/version`, {
            method: 'GET',
        }).then(({json}) => {
            return json.version;
        });

    },

    getPartyCdrStats: async (dateOpt: DashboardDateOption) : Promise<CdrStatistics[]> => {

        const {json} = await httpClient(`${apiUrl}/cdrs-stats/party/${dateOpt}`, {
            method: 'GET'
        })
        return json;
    },

    getIssuerCdrStats: async (dateOpt: DashboardDateOption, targetParty: string) : Promise<CdrStatistics[]> => {
        const {json} = await httpClient(`${apiUrl}/cdrs-stats/issuer/${dateOpt}${getOptParam("party", targetParty)}`, {
            method: 'GET'
        })
        return json;
    },

    getCdrOverTimeStats: async(typeOpt: CdrDashboardType): Promise<CdrStatisticsOverTime[]> => {
        const {json} = await httpClient(`${apiUrl}/cdrs-stats/line-data/${typeOpt}`, {
            method: 'GET'
        })
        return json;
    },

    getPartyList: async(): Promise<string[]> => {
        return (await httpClient(`${apiUrl}/cdrs-stats/party/`, {
            method: 'GET'
        })).json
    },

    patchCostOverride: (params: {cdrId: string, start_date_time: string, stop_date_time: string, total_cost: number}) => {
        const {
            cdrId,
            ...rest
        } = params;

        return httpClient(`${apiUrl}/cdrs/${cdrId}/cost`, {
            method: 'PATCH',
            body: JSON.stringify({...rest}),
        }).then(({ json }) => ({ data: json }));
    },

    getPermissions() : Promise<string[]> {
        return httpClient(`${apiUrl}/permissions`, {
            method: 'GET',
        }).then(({json}) => {
            return json;
        });
    },

    getConfig() : Promise<Configuration> {
        return httpClient(`${apiUrl}/configuration`, {
            method: 'GET',
        }).then(({json}) => {
            return json;
        });
    },

    getLocationsTags() : Promise<string[]> {
        return httpClient(`${apiUrl}/exploitation/locations/tags`, {
            method: 'GET',
        }).then(({json}) => {
            console.log(json);
            return json;
        });
    }
};

const filterDataUpdateTariffgroup = (data: any) => {
    return {
        tariffgroup_id: data.tariffgroup_id,
        subtariffs: data.subtariffs.map((subtariff: any) => {
            return {
                tariffkey: subtariff.tariffkey,
                tariff: subtariff.tariff
            }
        })
    }
}
const getOptParam = (name: string, param: string) => {
    return param ? `?${name}=${param}` : ``
}
export default Provider;
